import { Button, InlineAlert, useBreakpointValue } from "@alch/ui";
import { AlchemyBlack, AlchemyMark } from "@alch/ui/assets/logos";
import { ArrowLeft, ArrowRight } from "@alch/ui/icons/24";
import ProgressBar from "@features/signup/ProgressBar";
import { SignupStep, SignupStepsList } from "@features/signup/constants";
import useNavigateSignup from "@features/signup/hooks/useNavigateSignup";
import vectorBackground from "@static/signup/vector-background.svg";
import { Url } from "@util/urls";
import clsx from "clsx";
import React, { forwardRef, useMemo, useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import useCurrentUser from "../../../react-query/queries/useCurrentUser";
import ProgressCount from "../ProgressCount";

interface SignupTemplatePageProps {
  title: string;
  subtitle?: string | React.ReactNode;
  currentStep: SignupStep;
  onNextButtonClick?: () => void;
  enableSkipButton?: boolean;
  children: React.ReactNode;
  disableNextButton?: boolean;
  errorMessage?: string | null;
  isSubmitting?: boolean;
  formSubmitOverride?: boolean;
}

const SignupTemplatePage = forwardRef(function SignupTemplatePage(
  {
    title,
    subtitle,
    currentStep,
    onNextButtonClick,
    enableSkipButton = false,
    children,
    disableNextButton = false,
    errorMessage,
    isSubmitting,
    formSubmitOverride = false,
  }: SignupTemplatePageProps,
  ref: React.Ref<HTMLFormElement>,
) {
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const { data: user } = useCurrentUser();
  const { previousPath, goToNextStep, goToPreviousStep } = useNavigateSignup({
    currentStep,
  });

  useUpdateEffect(() => {
    if (formSubmitOverride || hasSubmittedForm) {
      goToNextStep();
    }
  }, [formSubmitOverride, hasSubmittedForm]);

  const stepIndex = SignupStepsList.indexOf(currentStep);

  const logo = useBreakpointValue({
    base: <img src={AlchemyMark} alt="Alchemy Logo" width={25} height={25} />,
    signupSm: (
      <img src={AlchemyBlack} alt="Alchemy Logo" width={130} height={25} />
    ),
  });

  const username = useBreakpointValue({
    base: user?.first_name.toLowerCase(),
    signupSm: user?.email,
  });

  const progress = useBreakpointValue({
    base: (
      <ProgressCount
        // Exclude enterprise details step
        currentStep={stepIndex >= 4 ? stepIndex : stepIndex + 1}
        totalSteps={SignupStepsList.length - 1}
      />
    ),
    signupMd: <ProgressBar steps={SignupStepsList} currentStep={currentStep} />,
  });

  const prevAndNextButtons = useMemo(
    () => (
      <div
        className={clsx(
          "flex",
          previousPath ? "justify-between" : "justify-end",
        )}
      >
        {previousPath && (
          <Button
            type="reset"
            intent="tertiary"
            icon={<ArrowLeft />}
            onClick={goToPreviousStep}
          />
        )}
        <div className="flex grid-cols-2 gap-x-4">
          {enableSkipButton && (
            <Button intent="tertiary" onClick={goToNextStep}>
              Skip bonus
            </Button>
          )}
          <Button
            type="button"
            loading={isSubmitting}
            trailingIcon={currentStep !== SignupStep.Referrer && <ArrowRight />}
            onClick={() => {
              onNextButtonClick?.();
              // Payment form requires additional verification through Chargify
              if (currentStep !== SignupStep.Payment) {
                setHasSubmittedForm(true);
              }
            }}
            disabled={disableNextButton}
          >
            {currentStep !== SignupStep.Referrer ? "Next" : "Let's build!"}
          </Button>
        </div>
      </div>
    ),
    [
      currentStep,
      disableNextButton,
      enableSkipButton,
      goToNextStep,
      goToPreviousStep,
      isSubmitting,
      onNextButtonClick,
      previousPath,
    ],
  );

  const navigationButtons = useBreakpointValue({
    base: (
      <div className="fixed bottom-0 left-0 w-screen bg-white/80 p-4 backdrop-blur-[6px]">
        {prevAndNextButtons}
      </div>
    ),
    signupMd: <div className="pb-6">{prevAndNextButtons}</div>,
  });

  return (
    <div
      style={{ backgroundImage: `url(${vectorBackground})` }}
      className={clsx("min-h-screen bg-cover bg-bottom bg-no-repeat pb-[70px]")}
    >
      <div className="grid grid-cols-3 px-6 py-3">
        <div className="flex justify-start">
          <a
            className="text-black"
            href={Url.Home}
            target="_blank"
            rel="noreferrer"
          >
            {logo}
          </a>
        </div>
        <div className="flex justify-center">{progress}</div>
        <div className="flex justify-end">
          <p className="text-paragraph-200-regular text-grayscale-700">
            {username}
          </p>
        </div>
      </div>
      <form ref={ref}>
        <div className="flex justify-center">
          <div className="inline-block justify-center px-3">
            <div className="my-6 flex flex-col items-center ">
              <div className="text-center text-heading-h2 text-grayscale-950 signup-sm:text-heading-h1">
                {title}
              </div>
              <div className="h-3"></div>
              <div className="text-center text-paragraph-200-regular text-grayscale-700 signup-sm:text-paragraph-300-regular">
                {subtitle}
              </div>
            </div>
            <div className="my-12 justify-center">{children}</div>
            {!!errorMessage && (
              <InlineAlert
                intent="error"
                leadingLabel="Error"
                className="mb-3 mt-12 w-full"
              >
                {errorMessage}
              </InlineAlert>
            )}
            {navigationButtons}
          </div>
        </div>
      </form>
    </div>
  );
});

export default SignupTemplatePage;
