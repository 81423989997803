import { z } from "zod";
import { NetworkId } from "./networkId";

export enum Flag {
  // Feature gates
  AlertChannel = "alert_channel",
  TestingSDK = "testing_sdk",
  NewCheckoutFlow = "new_checkout_flow",

  // Experiments
  SignupDeeplink = "signup_deeplink_new",
  QuickstartReorder = "quickstart_reorder",
  FaucetDashboardPromo = "faucet_dashboard_promo",

  // Dynamic configs
  OutageBanner = "outage_banner",
  Webhooks = "webhooks",
}
export const FlagEnum = z.nativeEnum(Flag);

// TODO: shared NetworkIdSchema
const NetworkIdSchema = z.string().transform((v) => v as NetworkId);
export const FLAG_SCHEMA_MAP = {
  // Feature gates
  [Flag.AlertChannel]: z.boolean(),
  [Flag.TestingSDK]: z.boolean(),
  [Flag.NewCheckoutFlow]: z.boolean(),

  // Experiments
  [Flag.SignupDeeplink]: z.object({ test: z.boolean() }),
  [Flag.QuickstartReorder]: z.object({
    quickstart_variant: z.union([z.literal("bottom"), z.literal("top")]),
  }),
  [Flag.FaucetDashboardPromo]: z.object({ test: z.boolean() }),

  // Dynamic configs
  [Flag.OutageBanner]: z.object({
    enabled: z.boolean(),
    message: z.string(),
  }),
  [Flag.Webhooks]: z.object({
    ADDRESS_ACTIVITY: z.array(NetworkIdSchema),
    GRAPHQL: z.array(NetworkIdSchema),
    NFT_ACTIVITY: z.array(NetworkIdSchema),
  }),
};

export const flagSchemaMap = z.object(FLAG_SCHEMA_MAP);
export type FlagTypes = z.infer<typeof flagSchemaMap>;

export const FLAG_DEFAULTS: FlagTypes = {
  [Flag.AlertChannel]: false,
  [Flag.TestingSDK]: false,
  [Flag.NewCheckoutFlow]: false,

  // Experiments
  [Flag.SignupDeeplink]: { test: false },
  [Flag.QuickstartReorder]: { quickstart_variant: "bottom" },
  [Flag.FaucetDashboardPromo]: { test: false },

  // Dynamic configs
  [Flag.OutageBanner]: { enabled: false, message: "" },
  [Flag.Webhooks]: {
    ADDRESS_ACTIVITY: [],
    GRAPHQL: [],
    NFT_ACTIVITY: [],
  },
};
