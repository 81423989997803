import { Button, IconButton } from "@alch/ui";
import { XCircleContained } from "@alch/ui/icons/24";
import clsx from "clsx";

import backdropBeigeUrl from "./assets/backdrop-beige.svg";
import backdropBlueUrl from "./assets/backdrop-blue.svg";
import backdropGreenUrl from "./assets/backdrop-green.svg";
import backdropPinkUrl from "./assets/backdrop-pink.svg";
import backdropPurpleUrl from "./assets/backdrop-purple.svg";
import backdropSkyBlueUrl from "./assets/backdrop-sky-blue.svg";
import backdropTealUrl from "./assets/backdrop-teal.svg";
import backdropYellowUrl from "./assets/backdrop-yellow.svg";
import CornerElementSvg from "./assets/corner-element.svg?react";

const themeBackdropUrls = {
  blue: backdropBlueUrl,
  skyBlue: backdropSkyBlueUrl,
  green: backdropGreenUrl,
  teal: backdropTealUrl,
  yellow: backdropYellowUrl,
  pink: backdropPinkUrl,
  purple: backdropPurpleUrl,
  beige: backdropBeigeUrl,
};

const titleHighlightClassNames = {
  blue: clsx("[&_em]:bg-vibrant-gradient-blue"),
  beige: clsx("[&_em]:bg-vibrant-gradient-yellow"),
  skyBlue: clsx("[&_em]:bg-vibrant-gradient-sky-blue"),
  green: clsx("[&_em]:bg-vibrant-gradient-green"),
  teal: clsx("[&_em]:bg-vibrant-gradient-green"),
  yellow: clsx("[&_em]:bg-vibrant-gradient-yellow"),
  purple: clsx("[&_em]:bg-vibrant-gradient-purple"),
  pink: clsx(
    "[&_em]:bg-gradient-to-br [&_em]:from-[#FF9C27] [&_em]:to-[#FD48CE]",
  ),
};

interface ProductBannerProps {
  theme: keyof typeof themeBackdropUrls;
  title: React.ReactNode;
  description: React.ReactNode;
  primaryButton: React.ComponentProps<typeof Button>;
  secondaryButton?: React.ComponentProps<typeof Button>;
  image: {
    url: string;
    width: number | string;
    height: number | string;
    className?: string;
  };
  onHide: () => void;
}

const ProductBanner = ({
  theme,
  title,
  description,
  primaryButton,
  secondaryButton,
  image,
  onHide,
}: ProductBannerProps) => {
  return (
    <div className="relative mb-5 flex overflow-hidden rounded-xl border border-grayscale-200 bg-white p-9">
      <div className="flex max-w-sm flex-col gap-3 xl:max-w-screen-sm 2xl:max-w-lg">
        <div
          className={clsx(
            "text-heading-h1 text-grayscale-950",
            "[&_em]:bg-clip-text [&_em]:not-italic [&_em]:text-transparent",
            titleHighlightClassNames[theme],
          )}
        >
          {title}
        </div>

        <p className="text-paragraph-200-regular text-grayscale-500 xl:text-lg">
          {description}
        </p>

        <div className="mt-auto flex flex-wrap gap-3">
          <Button
            size="lg"
            className="h-11 bg-grayscale-950"
            {...primaryButton}
          />

          {secondaryButton && (
            <Button
              size="lg"
              intent="tertiary"
              className="h-11"
              {...secondaryButton}
            />
          )}
        </div>
      </div>

      <div
        className="relative -m-9 ml-auto flex w-2/3 max-w-screen-lg"
        style={{
          background: `url(${themeBackdropUrls[theme]}) center no-repeat`,
          minHeight: 280,
          minWidth: 160,
        }}
      >
        <CornerElementSvg className="absolute -left-px" />

        <div
          className="absolute -inset-x-0 bottom-0 bg-white"
          style={{ top: 340 }}
        />

        <div className="absolute inset-0 ml-2 flex lg:ml-24">
          <img
            src={image.url}
            alt=""
            className={image.className ?? "mx-auto mt-auto"}
            style={{ maxWidth: image.width, height: image.height }}
          />
        </div>
      </div>

      <IconButton
        className="absolute right-3 top-3 text-grayscale-950"
        background="dark"
        onClick={onHide}
      >
        <XCircleContained className="size-5" />
      </IconButton>
    </div>
  );
};

export default ProductBanner;
