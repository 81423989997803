/**
 * Taken from @alch/protobufs/frontend
 * The file was not being properly tree-shaken, so just importing these files here.
 */

export enum ProtoNetwork {
  NETWORK_UNSPECIFIED = 0,
  ETH_MAINNET = 1,
  ETH_ROPSTEN = 2,
  ETH_RINKEBY = 3,
  ETH_KOVAN = 4,
  ETH_GOERLI = 5,
  ETH_SEPOLIA = 6,
  ETH_HOLESKY = 7,
  CRO_MAINNET = 100,
  CRO_CROESEID = 101,
  ARB_MAINNET = 201,
  ARB_RINKEBY = 203,
  ARB_GOERLI = 205,
  ARB_SEPOLIA = 206,
  FLOW_MAINNET = 300,
  FLOW_TESTNET = 301,
  MATIC_MAINNET = 401,
  MATIC_MUMBAI = 406,
  MATIC_AMOY = 407,
  OPT_MAINNET = 501,
  OPT_KOVAN = 504,
  OPT_GOERLI = 505,
  OPT_SEPOLIA = 506,
  SOLANA_MAINNET = 701,
  SOLANA_TESTNET = 702,
  SOLANA_DEVNET = 703,
  ASTAR_MAINNET = 801,
  ASTAR_SHIDEN = 802,
  ASTAR_SHIBUYA = 803,
  POLYGONZKEVM_MAINNET = 901,
  POLYGONZKEVM_TESTNET = 902,
  POLYGONZKEVM_CARDONA = 903,
  STARKNET_MAINNET = 1001,
  STARKNET_GOERLI = 1002,
  STARKNET_SEPOLIA = 1003,
  BASE_MAINNET = 1101,
  BASE_GOERLI = 1102,
  BASE_SEPOLIA = 1103,
  ZKSYNC_MAINNET = 1201,
  ZKSYNC_SEPOLIA = 1202,
  ZORA_MAINNET = 1301,
  ZORA_SEPOLIA = 1302,
  FRAX_MAINNET = 1401,
  FRAX_SEPOLIA = 1402,
  BNB_MAINNET = 1501,
  BNB_TESTNET = 1502,
  AVAX_MAINNET = 1601,
  AVAX_FUJI = 1602,
  ARBNOVA_MAINNET = 1701,
  ALCHEMY_MAINNET = 1801,
  ALCHEMY_SEPOLIA = 1802,
  ALCHEMY_INTERNAL = 1803,
  WORLDCHAIN_MAINNET = 1901,
  WORLDCHAIN_SEPOLIA = 1902,
  ZETACHAIN_MAINNET = 2001,
  ZETACHAIN_TESTNET = 2002,
  BLAST_MAINNET = 2101,
  BLAST_SEPOLIA = 2102,
  SCROLL_MAINNET = 2201,
  SCROLL_SEPOLIA = 2202,
  LINEA_MAINNET = 2301,
  LINEA_SEPOLIA = 2302,
  GNOSIS_MAINNET = 2401,
  GNOSIS_CHIADO = 2402,
  FANTOM_MAINNET = 2501,
  FANTOM_TESTNET = 2502,
  MANTLE_MAINNET = 2601,
  MANTLE_SEPOLIA = 2602,
  CELO_MAINNET = 2701,
  CELO_ALFAJORES = 2702,
  BERACHAIN_ARTIO = 2801,
  BERACHAIN_BARTIO = 2802,
  METIS_MAINNET = 2901,
  OPBNB_MAINNET = 3001,
  OPBNB_TESTNET = 3002,
  POLYNOMIAL_MAINNET = 3101,
  POLYNOMIAL_SEPOLIA = 3102,
  SHAPE_MAINNET = 3201,
  SHAPE_SEPOLIA = 3202,
  BOB_MAINNET = 3301,
  CROSSFI_TESTNET = 3401,
  IOTA_MAINNET = 3501,
  IOTA_TESTNET = 3502,
  MODE_MAINNET = 3601,
  MODE_SEPOLIA = 3602,
  MOONBASE_ALPHA = 3701,
  MOONBEAM_MAINNET = 3801,
  MOONRIVER_MAINNET = 3901,
  PALM_MAINNET = 4001,
  PALM_TESTNET = 4002,
  SHIDEN_MAINNET = 4101,
  SHIDEN_SHIBUYA = 4102,
  SEI_MAINNET = 4201,
  SEI_TESTNET = 4202,
  KINTO_MAINNET = 4301,
  PLUME_TESTNET = 4402,
  ANIME_SEPOLIA = 4502,
  RACE_MAINNET = 4601,
  RACE_SEPOLIA = 4602,
  APECHAIN_MAINNET = 4701,
  APECHAIN_CURTIS = 4702,
}

export enum TransactionStatus {
  TRANSACTION_STATUS_UNSPECIFIED = 0,
  MINED = 1,
  PENDING = 2,
  DROPPED = 3,
  REORGED = 4,
  FINALIZED = 5,
  EXECUTED = 6,
  SEALED = 7,
  EXPIRED = 8,
  DROPPED_AND_REPLACED = 9,
}

// Blockchain enum value used in protobufs (https://github.com/OMGWINNING/protobufs/blob/master/src/main/proto/common.proto#L9)
export enum ProtoChain {
  ETH = 1,
  ARB = 3,
  FLOW = 4,
  MATIC = 5,
  OPT = 6,
  SOLANA = 8,
  ASTAR = 9,
  POLYGONZKEVM = 10,
  STARKNET = 11,
  BASE = 12,
  ZKSYNC = 13,
  ZORA = 14,
  FRAX = 15,
  BNB = 16,
  AVAX = 17,
  ARBNOVA = 18,
  ALCHEMY = 19,
  WORLDCHAIN = 20,
  ZETACHAIN = 21,
  BLAST = 22,
  SCROLL = 23,
  LINEA = 24,
  GNOSIS = 25,
  FANTOM = 26,
  MANTLE = 27,
  CELO = 28,
  BERACHAIN = 29,
  METIS = 30,
  OPBNB = 31,
  POLYNOMIAL = 32,
  SHAPE = 33,
  BOB = 34,
  CROSSFI = 35,
  IOTA = 36,
  MODE = 37,
  MOONBASE = 38,
  MOONBEAM = 39,
  MOONRIVER = 40,
  PALM = 41,
  SHIDEN = 42,
  SEI = 43,
  KINTO = 44,
  PLUME = 45,
  ANIME = 46,
  RACE = 47,
  APECHAIN = 48,
}
