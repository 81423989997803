import { Documentation } from "@alch/ui/icons/24";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import { Path } from "@util/paths";
import { AlchemyDocsUrl } from "@util/urls";
import ProductBanner from "./ProductBanner";
import crossfiShippedLogo from "./assets/crossfi-shipped-logo.svg";

interface ProductLaunchAnnouncementProps {
  onHide: () => void;
}

const ProductLaunchAnnouncement = ({
  onHide,
}: ProductLaunchAnnouncementProps) => {
  return (
    <ProductBanner
      theme="blue"
      title="CrossFi Testnet is live!"
      description="Build with unlimited scalability and EVM and Cosmos interoperability."
      primaryButton={{
        as: ViewAsPreservingLink,
        to: `${Path.CreateApp}?utm_source=launchbanner&utm_medium=website&utm_campaign=crossfi`,
        children: "Create a new app",
        onClick: onHide,
      }}
      secondaryButton={{
        as: "a",
        href: AlchemyDocsUrl.CrossFiQuickStart,
        target: "_blank",
        children: "Docs",
        icon: <Documentation />,
      }}
      image={{
        url: crossfiShippedLogo,
        width: 300,
        height: "50%",
        className: "ml-auto mr-12 my-auto",
      }}
      onHide={onHide}
    />
  );
};

export default ProductLaunchAnnouncement;
