import { ONE_DAY_MS } from "@features/common/DateUtils";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/root";

export enum ExpirableEvent {
  USAGE_WARNING_BANNER_DISMISSED = "usageWarningBannerDismissed",
  USAGE_DANGER_BANNER_DISMISSED = "usageDangerBannerDismissed",
  USAGE_LIMIT_BANNER_DISMISSED = "usageLimitBannerDismissed",
  UPGRADE_ONBOARDING_COLLAPSED = "upgradeOnboardingCollapsed",
  UPGRADE_ONBOARDING_COMPLETED = "upgradeOnboardingCompleted",
}

function getExpirableEventKey(
  userId: number | undefined,
  event: ExpirableEvent,
): string {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- FIXME
  return `alchemyapi.expirableEvent.${userId}.${event}`;
}

export function useExpirableEventStorage(
  event: ExpirableEvent,
  // session storage is cleared when the tab is closed, but for situation where user
  // leaves their tab forever, we will fall back to the expiration time
  timeToLive = ONE_DAY_MS,
  storageType: "session" | "local" = "local",
): [boolean, () => void, () => void] {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const userId = useSelector(
    (state: RootState) => state.users.currentUser.value?.id,
  );
  const key = getExpirableEventKey(userId, event);
  const value = { expiration: new Date().getTime() + timeToLive };
  const [isEventSet, setIsEventSet] = useState(
    userId ? isEventRegistered(storage, key) : true,
  );
  useEffect(() => {
    if (userId) {
      setIsEventSet(isEventRegistered(storage, key));
    }
  }, [storage, userId, key]);
  const setEvent = () => {
    setIsEventSet(true);
    storage.setItem(key, JSON.stringify(value));
  };
  const clearEvent = () => {
    setIsEventSet(false);
    storage.removeItem(key);
  };

  return [isEventSet, setEvent, clearEvent];
}

function isEventRegistered(storage: Storage, key: string): boolean {
  const valueStr = storage.getItem(key);
  if (!valueStr) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
  const item = JSON.parse(valueStr);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
  if (new Date().getTime() > item.expiration) {
    storage.removeItem(key);
    return false;
  }
  return true;
}

export enum OneTimeEvent {
  GettingStartedCollapsed = "gettingStartedCollapsed",
  AlertsNewNav = "alertsNewNav",
  HomeRecommendedProduct = "homeRecommendedProduct",
  MultiNetworkAppDetailsTooltip = "multiNetworkAppDetailsTooltip",
  BerachainLaunch = "berachainLaunch",
  CrossFiLaunch = "crossfiLaunch",
}

function getOneTimeEventKey(
  userId: number | undefined,
  event?: OneTimeEvent,
): string | null {
  if (!userId || !event) return null;

  return `alchemyapi.oneTimeEvent.${userId}.${event}`;
}

export function useOneTimeEventStorage(
  event?: OneTimeEvent,
): [boolean, () => void, () => void] {
  const userId = useSelector(
    (state: RootState) => state.users.currentUser.value?.id,
  );
  const key = getOneTimeEventKey(userId, event);
  const [hasEventOccured, setHasEventOccured] = useState(true);
  useEffect(() => {
    if (userId && key) {
      setHasEventOccured(hasEventHappened(key));
    }
  }, [userId, key]);
  const setEventOccured = useCallback(() => {
    if (!hasEventOccured && key) {
      setHasEventOccured(true);
      const value = { eventOccured: true };
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [hasEventOccured, key]);
  const clearEventOccured = useCallback(() => {
    if (hasEventOccured && key) {
      setHasEventOccured(false);
      localStorage.removeItem(key);
    }
  }, [hasEventOccured, key]);
  return [hasEventOccured, setEventOccured, clearEventOccured];
}

function hasEventHappened(key: string): boolean {
  return !!localStorage.getItem(key);
}
