import { Flag } from "@alch/dx-webapp-api/shared";
import { Loading } from "@alch/ui";
import { Route } from "@components/app/Route.tsx";
import ChainPage from "@features/signup/Pages/ChainPage";
import EnterpriseDetailsPage from "@features/signup/Pages/EnterpriseDetailsPage";
import PaymentPage from "@features/signup/Pages/PaymentPage";
import PlanPage from "@features/signup/Pages/PlanPage";
import ProjectPage from "@features/signup/Pages/ProjectPage";
import ReferrerPage from "@features/signup/Pages/ReferrerPage";
import TeamPage from "@features/signup/Pages/TeamPage";
import SignupFlowProvider from "@features/signup/SignupFlowProvider";
import {
  SignupStep,
  SignupStepPathMap,
  SignupStepsList,
} from "@features/signup/constants";
import { useFlag } from "@hooks/useFlag";
import { SearchParam } from "@hooks/useSearchParam";
import { Path } from "@util/paths";
import { useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import useAnalyticsInSignup from "../../hooks/useAnalyticsInSignup";

const SignupStepComponentMap = {
  [SignupStep.Team]: TeamPage,
  [SignupStep.Project]: ProjectPage,
  [SignupStep.Chain]: ChainPage,
  [SignupStep.Plan]: PlanPage,
  [SignupStep.EnterpriseDetails]: EnterpriseDetailsPage,
  [SignupStep.Payment]: PaymentPage,
  [SignupStep.Referrer]: ReferrerPage,
};

const SignupRoutes = () => {
  useAnalyticsInSignup();

  const history = useHistory();
  const { search, pathname } = history.location;
  const searchParams = new URLSearchParams(search);

  const isOnProductLanding =
    pathname.startsWith(Path.Products) && !pathname.startsWith(Path.Products);

  const hasRedirectParam = searchParams.has(SearchParam.RedirectUrl);

  // Evaluate experiment only for users being redirected from product landing page
  const signupDeeplinkFlag = useFlag(Flag.SignupDeeplink, {
    enabled: isOnProductLanding && !hasRedirectParam,
  });

  if (signupDeeplinkFlag.data?.test) {
    searchParams.set(SearchParam.RedirectUrl, `${pathname}${search}`);
  }

  const defaultLocation = {
    ...history.location,
    search: searchParams.toString(),
    pathname: SignupStepPathMap[SignupStep.Team],
  };

  useEffect(() => {
    if (signupDeeplinkFlag.isLoading) return;

    // Send user back to start of signup flow if they refresh during signup
    history.replace(defaultLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run once, on mount, when flag is loaded
  }, [signupDeeplinkFlag.isLoading]);

  return (
    <SignupFlowProvider>
      <Switch>
        {SignupStepsList.map((step) => (
          <Route
            key={step}
            path={SignupStepPathMap[step]}
            component={SignupStepComponentMap[step]}
            exact
          />
        ))}

        <Route>
          <Loading className="mx-auto my-10" />
        </Route>
      </Switch>
    </SignupFlowProvider>
  );
};

export default SignupRoutes;
