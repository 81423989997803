import { useQuery } from "@tanstack/react-query";
import { find } from "lodash";
import { useMemo } from "react";
import { getWaffleFlags } from "../../http/endpoints";

enum WaffleFlag {
  BNBMainnet = "bnb-mainnet-allowlist",
  BNBTestnet = "bnb-testnet-allowlist",
  GasManagerPredeposit = "gas-manager-predeposit",
  SignerService = "signer-service",
  RaasDashboard = "enable_raas_dashboard",
  Pipelines = "allow_data_platform",
  UsageLimitBanner = "usage_limit_banner",
  UsageLimitBannerHoverState = "usage_limit_banner_hover_state",
  ZKsync = "zksync",
  EthHolesky = "eth-holesky",
  AvaxMainnet = "avax-mainnet-allowlist",
  AvaxFuji = "avax-fuji-allowlist",
  ArbNova = "arb-nova",
  AlchemyMainnet = "alchemy-mainnet",
  AlchemySepolia = "alchemy-sepolia",
  AlchemyInternal = "alchemy-internal",
  WorldChainMainnet = "worldchain-mainnet",
  WorldChainSepolia = "worldchain-sepolia",
  ZetaChain = "zetachain",
  Blast = "blast",
  Scroll = "scroll",
  Linea = "linea",
  Gnosis = "gnosis",
  Fantom = "fantom",
  FlowMainnet = "flow-mainnet-allowlist",
  FlowTestnet = "flow-testnet-allowlist",
  MantleMainnet = "mantle",
  MantleSepolia = "mantle-sepolia",
  Celo = "celo",
  BerachainArtio = "berachain",
  BerachainBArtio = "berachain-bartio-allowlist",
  MetisMainnet = "metis-mainnet-allowlist",
  opBNB = "opbnb",
  PolynomialMainnet = "polynomial-mainnet-allowlist",
  PolynomialSepolia = "polynomial-sepolia-allowlist",
  ShapeMainnet = "shape-mainnet-allowlist",
  ShapeSepolia = "shape-sepolia-allowlist",
  BOBMainnet = "bob-mainnet-allowlist",
  CrossFiTestnet = "crossfi-testnet-allowlist",
  IotaMainnet = "iota-mainnet-allowlist",
  IotaTestnet = "iota-testnet-allowlist",
  ModeMainnet = "mode-mainnet-allowlist",
  ModeSepolia = "mode-sepolia-allowlist",
  MoonbaseAlpha = "moonbase-alpha-allowlist",
  MoonbeamMainnet = "moonbeam-mainnet-allowlist",
  MoonriverMainnet = "moonriver-mainnet-allowlist",
  PalmMainnet = "palm-mainnet-allowlist",
  PalmTestnet = "palm-testnet-allowlist",
  ShidenMainnet = "shiden-mainnet-allowlist",
  ShidenShibuya = "shiden-shibuya-allowlist",
  SeiMainnet = "sei-mainnet-allowlist",
  SeiTestnet = "sei-testnet-allowlist",
  KintoMainnet = "kinto-mainnet-allowlist",
  PlumeTestnet = "plume-testnet-allowlist",
  AnimeTestnet = "anime-sepolia-allowlist",
  RaceMainnet = "race-mainnet-allowlist",
  RaceTestnet = "race-sepolia-allowlist",
  ApeChainMainnet = "apechain-mainnet-allowlist",
  ApeChainCurtis = "apechain-curtis-allowlist",
}

const WAFFLE_FLAG_KEY = "featureFlags";

function useWaffleFlags<TFlag extends WaffleFlag>(
  featureFlags: TFlag[],
): Record<TFlag, boolean> {
  const { flags } = useWaffleFlagsLoading(featureFlags);
  return flags;
}

function useWaffleFlag(featureFlag: WaffleFlag) {
  const flags = useWaffleFlags([featureFlag]);
  return flags[featureFlag];
}

/**
 * Prefer to use this hook over `useWaffleFlags` so you can check the loading state of the flag to prevent
 * seeing a blip on the page when the flag switches from false to true.
 */
function useWaffleFlagsLoading<TFlag extends WaffleFlag>(
  featureFlags: TFlag[],
): { isLoading: boolean; flags: Record<TFlag, boolean> } {
  const { data: flagObjs, isLoading } = useQuery({
    queryKey: [WAFFLE_FLAG_KEY, featureFlags],
    queryFn: () => getWaffleFlags({ flags: featureFlags }),
    staleTime: 60 * 1000,
  });

  const flags = useMemo(
    () =>
      featureFlags.reduce(
        (acc, flag) => {
          // Flags default to false if they can't be loaded
          acc[flag] = find(flagObjs || [], { name: flag })?.active ?? false;
          return acc;
        },
        {} as Record<TFlag, boolean>,
      ),
    [featureFlags, flagObjs],
  );

  return {
    isLoading,
    flags,
  };
}

function useWaffleFlagLoading(featureFlag: WaffleFlag) {
  const { flags, isLoading } = useWaffleFlagsLoading([featureFlag]);
  return { value: flags[featureFlag], isLoading };
}

export {
  useWaffleFlag,
  useWaffleFlagLoading,
  useWaffleFlags,
  useWaffleFlagsLoading,
  WaffleFlag,
};
